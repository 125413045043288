import React, { useState, useEffect } from 'react';
import  { useRef } from 'react';
import AWS from 'aws-sdk';

 

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';



import { Container, Form, Button } from 'react-bootstrap';
import { Amplify, Auth } from 'aws-amplify';


import config from './appenvdata.json';




Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  }
});

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}



function App() {

  

  return (
    <div >
      <h2 class="heading">AutomateMM</h2>
    
      
    <Container>

     

     
      <div class="product-description">
      AutomateMM offers a centralised repository for all automation assets and scripts across APJ, allowing for easy access and utilisation. One of its key features is the ability to track the usage of these assets, enabling us to measure their value in terms of human hours saved and the speed of delivery. 
By automating repetitive tasks, AutomateMM significantly enhances the efficiency and productivity of MMSAs and Non-MMSA's.
   </div>
     
  
 
</Container>





<div className="product-description">
      <div className="container">
        <h3>About</h3>
        <p>Owner: <span id="version-number"> <b> <a class="phone-link" href="https://phonetool.amazon.com/users/debsami">Samit Deb</a> </b></span></p>
        
        <div class="progress-text">{/*<span className="tooltip" data-status="Inprogress"><FontAwesomeIcon icon={faCircle} className="not-started-icon amber-icon" /></span>*/}
        This tool is currently being developed. Tentative ETA - Q4 2025</div>
        {/* 
        <p>Current Version: <span id="version-number"><b>1.1</b></span></p>
        <p>Last Updated: <span id="last-updated-date"><b>February 5, 2024</b></span></p>
        <p>Asset use count: <span id="asset-use-count"><b>11</b> </span></p>
        <br></br>
        
        <h3>Version History</h3>
        
        <div className="version-wrapper">
          <p><span id="version-number"><b>Version 1.1</b></span></p>
          <p>Details</p>
        </div>
        <div >
          <p><span id="version-number"><b>Version 1</b></span></p>
          <p>Details</p>
        </div>

        <br></br>
        
        <h3>Coming up next </h3>
       
        <p>Details</p>
        */}
      </div>
    </div>




</div>
  );
}

export default App;
