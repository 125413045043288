import React, { useEffect, useState } from 'react';
import Main from './Main';
import MainPage from './MainPage';
import EstimateValidation from './EstimateValidation';
import WorkloadAssessmentPro from './WorkloadAssessmentPro';
import DispositionModelling from './DispositionModelling';
import './App.css';
import StorageRefactoring from './StorageRefactoring';
import Maptag from './MapSmartTagging';
import ModernizationAtScale from './ModernizationAtScale'
import DRMaster from './DRMaster';
import BackupAssist from './BackupAssist';
import MigrationDataCollectionWizard from './MigrationDataCollectionWizard'; 
import ModernizationEstimator from './ModernizationEstimator';
import { AuthProvider } from './AuthContext'; 
import MapTagger from './MapTagger';


import AddCust from './AddCust';
import ViewCust from './ViewCust';
import UpdateCust from './UpdateCust';

import AddTemp from './AddTemp';
import ViewTemp from './ViewTemp';
import UpdateTemp from './UpdateTemp';

import Footer from './Footer'; // Import the Footer component

import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircle} from '@fortawesome/free-solid-svg-icons';

import { faCaretRight } from '@fortawesome/free-solid-svg-icons';


import { withAuthenticator } from '@aws-amplify/ui-react';



import '@aws-amplify/ui-react/styles.css';

import { jwtDecode } from 'jwt-decode'; // Ensure jwt-decode is installed
import { Amplify, Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import config from './appenvdata.json';
import { addEntryToDynamoDB } from './dynamoDbService'; 



Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId,
    region: config.region,
    userPoolId:  config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    cognitoDomain:config.cognitoDomain,
    redirectUri:config.redirectUri
  }
})

const credentials = await Auth.currentCredentials();

export function configureAWS(credentials, currentRegion) {
  AWS.config.update({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: currentRegion,
  });
}


<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>


const Sidebar = ({ handleSectionChange, userAttributes }) => {

  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [SelectedSub1, setSelectedSub1] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isModernizationSubMenuOpen, setIsModernizationSubMenuOpen] = useState(false);
  const [isWorkloadSubMenuOpen, setIsWorkloadSubMenuOpen] = useState(false);
  const [isCustMenuOpen, setIsCustMenuOpen] = useState(false);
  const [isTempMenuOpen, setIsTempMenuOpen] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
  
    if (!hasReloaded) {
      localStorage.setItem('hasReloaded', 'true'); // Set the flag
      window.location.reload(); // Reload the page
    }
  
    // Optionally clear the flag when needed, e.g., on logout or certain conditions
    return () => {
      // Clean up if necessary
    };
  }, []);
  

  useEffect(() => {
    setUserEmail(userAttributes?.email);
    setUserFirstName(userAttributes?.given_name);
    setUserLastName(userAttributes?.family_name);
   
  }, [userAttributes]);


  const handleLinkClick = (section) => {

    handleSectionChange(section);

    if (section === 'ModernizationAtScale') {
      
      setIsModernizationSubMenuOpen(!isModernizationSubMenuOpen);
      setIsWorkloadSubMenuOpen("");
      
      
    } else if (section === 'WorkloadAssessmentPro') {
      setIsWorkloadSubMenuOpen(!isWorkloadSubMenuOpen);
      setIsModernizationSubMenuOpen("")
    }
    else if (section !== 'WorkloadAssessmentPro') {
     
      setIsModernizationSubMenuOpen("")
      setIsWorkloadSubMenuOpen("");
    }

    setSelectedSection(section === selectedSection ? null : section);
    localStorage.setItem('selectedSection', section);
  };


  const handlesubLinkClick = (sectionId) => {
    if(sectionId ==='Customer')
    {
      setIsTempMenuOpen("")
      setIsCustMenuOpen(!isCustMenuOpen)
      
    }
    else if(sectionId ==='Template')
    
    {
      setIsCustMenuOpen("")
      setIsTempMenuOpen(!isTempMenuOpen)
      
    }
    setSelectedSub1(sectionId);
  };

  
  const renderSubMenu = () => {
    if (selectedSection === 'WorkloadAssessmentPro') {
      return (
        <ul className="submenu">
          <li>
            <a href="#Customer" onClick={() => handlesubLinkClick('Customer')}>
              &nbsp;&nbsp;&nbsp;<FontAwesomeIcon
                icon={faCaretRight}
                style={{
                  transition: 'transform 0.3s',
                  transform: `rotate(${isCustMenuOpen ? 90 : 0}deg)`,
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />&nbsp;&nbsp;Customer
            </a>
            {SelectedSub1 === 'Customer' && (
              <ul className="subsubmenu">
                <li>
                  <a href="#AddCust" onClick={() => handleSectionChange('AddCust')}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add Customer
                  </a>
                </li>
                <li>
                  <a href="#ViewCust" onClick={() => handleSectionChange('ViewCust')}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View Customer
                  </a>
                </li>
                <li>
                  <a href="#UpdateCust" onClick={() => handleSectionChange('UpdateCust')}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Update Customer
                  </a>
                </li>
              </ul>
            )}
          </li>
          <li>
            <a href="#Template" onClick={() => handlesubLinkClick('Template')}>
              &nbsp;&nbsp;&nbsp;<FontAwesomeIcon
                icon={faCaretRight}
                style={{
                  transition: 'transform 0.3s',
                  transform: `rotate(${isTempMenuOpen ? 90 : 0}deg)`,
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />&nbsp;&nbsp;Template
            </a>
            {SelectedSub1 === 'Template' && (
              <ul className="subsubmenu">
                <li>
                  <a href="#AddTemp" onClick={() => handleSectionChange('AddTemp')}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add Template
                  </a>
                </li>
                <li>
                  <a href="#ViewTemp" onClick={() => handleSectionChange('ViewTemp')}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View Template
                  </a>
                </li>
                <li>
                  <a href="#UpdateTemp" onClick={() => handleSectionChange('UpdateTemp')}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Update Template
                  </a>
                </li>
              </ul>
            )}
          </li>
        </ul>
      );
    }
    return null;
  };
  
  const renderSubMenuModernizationAtScale = () => {
    if (selectedSection === 'ModernizationAtScale') {
      return (
        <ul className="submenu">
          <li>
            <a
              href="https://w.amazon.com/bin/view/India-Mod/Modernization/decision-tree#HMODERNIZATIONSCORINGMODEL"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                handleClick('Modernization Score generator');
                // Optionally open the link in a new tab after the async operation
                window.open(e.currentTarget.href, '_blank');
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modernization Score generator
              <span className="tooltip" data-status="m@s">
                <i className="fa fa-external-link blue-icon" aria-hidden="true"></i>
              </span>
            </a>
          </li>
  
          <li>
            <a
              href="https://w.amazon.com/bin/view/India-Mod/Modernization/Qualification-Checklist/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                handleClick('Qualification checklist');
                // Optionally open the link in a new tab after the async operation
                window.open(e.currentTarget.href, '_blank');
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Qualification checklist
              <span className="tooltip" data-status="m@s">
                <i className="fa fa-external-link blue-icon" aria-hidden="true"></i>
              </span>
            </a>
          </li>
  
          <li>
            <a
              href="#ModernizationEstimator"
              className="sidebar-link"
              onClick={() => handleSectionChange('ModernizationEstimator')}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modernization Estimator &nbsp;
              <span className="tooltip" data-status="Yet-to-start">
                <FontAwesomeIcon icon={faCircle} className="not-started-icon gray-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br />
          </li>
        </ul>
      );
    }
    return null;
  };
  const handleClick = async (toolName) => {
    
    try {
     
      await addEntryToDynamoDB('', toolName, userEmail, userFirstName, userLastName);
      // Optionally, add logic here after successful entry, like a success message
    } catch (error) {
      console.error("Error adding entry:", error);
      // Optionally, add error handling logic
    }
  };
  return (
    <div className="sidebar">
      <h2 style={{ textAlign: 'left' }}>
        <img
          src="aws1.png"
          alt="Amazon Logo"
          width="60px"
          height="auto"
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        />
        AutomateMM
      </h2>
      <br />
      <nav>
        <ul>
          <li>
      
            <a
              href="#EstimateValidation"
              id="estimateLink"
              className="sidebar-link"
              onClick={() => handleLinkClick('EstimateValidation')}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estimate Validation &nbsp;
              <span className="tooltip" data-status="Completed">
                <FontAwesomeIcon icon={faCircle} className="check-icon green-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br />
  
            <a
              href="#ModernizationAtScale"
              id="ModernizationAtScaleLink"
              className="sidebar-link"
              onClick={() => handleLinkClick('ModernizationAtScale')}
            >
              <FontAwesomeIcon
                icon={faCaretRight}
                style={{
                  transition: 'transform 0.3s',
                  transform: `rotate(${isModernizationSubMenuOpen ? 90 : 0}deg)`,
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />{' '}
              &nbsp;Modernization@Scale &nbsp;
              <span className="tooltip" data-status="Completed">
                <FontAwesomeIcon icon={faCircle} className="check-icon green-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br />
  
            {isModernizationSubMenuOpen && renderSubMenuModernizationAtScale()}
  
            <a
              href="#WorkloadAssessmentPro"
              id="workloadLink"
              className="sidebar-link"
              onClick={() => handleLinkClick('WorkloadAssessmentPro')}
            >
              <FontAwesomeIcon
                icon={faCaretRight}
                style={{
                  transition: 'transform 0.3s',
                  transform: `rotate(${isWorkloadSubMenuOpen ? 90 : 0}deg)`,
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />{' '}
              &nbsp;Workload Assessment Pro &nbsp;
              <span className="tooltip" data-status="Completed">
                <FontAwesomeIcon icon={faCircle} className="check-icon green-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br/>

            


  
            
            {isWorkloadSubMenuOpen && renderSubMenu()}
            <a
              href="#MapTagger"
              className="sidebar-link"
              onClick={() => handleSectionChange('MapTagger')}
            >
              {' '}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Map Smart Tagger &nbsp;
              <span className="tooltip" data-status="Completed">
                <FontAwesomeIcon icon={faCircle} className="check-icon green-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br />


            <a
              href="#DispositionModelling"
              className="sidebar-link"
              onClick={() => handleLinkClick('DispositionModelling')}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disposition Modelling &nbsp;
              <span className="tooltip" data-status="In-progress">
                <FontAwesomeIcon icon={faCircle} className="not-started-icon amber-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br />
  
            <a
              href="#StorageRefactoring"
              className="sidebar-link"
              onClick={() => handleSectionChange('StorageRefactoring')}
            >
              {' '}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Storage Refactoring &nbsp;
              <span className="tooltip" data-status="Yet-to-start">
                <FontAwesomeIcon icon={faCircle} className="not-started-icon gray-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br />
  
            <a
              href="#DRMaster"
              className="sidebar-link"
              onClick={() => handleSectionChange('DRMaster')}
            >
              {' '}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DRMaster&nbsp;
              <span className="tooltip" data-status="Yet-to-start">
                <FontAwesomeIcon icon={faCircle} className="not-started-icon gray-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br />
  
            <a
              href="#BackupAssist"
              className="sidebar-link"
              onClick={() => handleSectionChange('BackupAssist')}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BackupAssist&nbsp;
              <span className="tooltip" data-status="Yet-to-start">
                <FontAwesomeIcon icon={faCircle} className="not-started-icon gray-icon" style={{ fontSize: '0.6em' }} />
              </span>
            </a>
            <br />
  
           
  
            <a
              href="https://main.dztaiwyof4x0h.amplifyapp.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="tuttu-link"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                handleClick('Tuttu');
                // Optionally open the link in a new tab after the async operation
                window.open(e.currentTarget.href, '_blank');
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tuttu
              <span className="tooltip" data-status="tuttu">
                <i className="fa fa-external-link blue-icon" aria-hidden="true"></i>
              </span>
            </a>
  
            <a
              href="https://main.d3uxsmjatgpde0.amplifyapp.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="modpathrec-link"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                handleClick('ModernizationPathwayRecommender');
                // Optionally open the link in a new tab after the async operation
                window.open(e.currentTarget.href, '_blank');
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modernization Pathway &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Recommender
              <span className="tooltip" data-status="ModernizationPathwayRecommender">
                <i className="fa fa-external-link blue-icon" aria-hidden="true"></i>
              </span>

              
            </a>


            <a
              href="http://msw-kpi-recommender-alb-112795239.ap-northeast-1.elb.amazonaws.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="modpathrec-link"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                handleClick('KPI Recommender');
                // Optionally open the link in a new tab after the async operation
                window.open(e.currentTarget.href, '_blank');
              }}
            >
               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modernization KPI &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Recommender
              <span className="tooltip" data-status="KPI Recommender">
                <i className="fa fa-external-link blue-icon" aria-hidden="true"></i>
              </span>
            </a>





          </li>
        </ul>
      </nav>
    </div>
  );
  
  
};

const App = () => {
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userAttributes, setUserAttributes] = useState(() => {
    const savedAttributes = localStorage.getItem('userAttributes');
    return savedAttributes ? JSON.parse(savedAttributes) : null;
  });

  const { userPoolId, userPoolWebClientId: clientId, cognitoDomain, redirectUri } = config;

  const responseType = 'code';
  const scope = 'openid profile email';
  const tokenURI = `${cognitoDomain}/oauth2/token`;
  const cognitoLoginUrl = `${cognitoDomain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}`;



  
  useEffect(() => {
    const fetchCredentials = async () => {
      try {
        const credentials = await Auth.currentCredentials();
        configureAWS(credentials);
      } catch (error) {
        console.error('Error fetching credentials:', error);
      }
    };

    if (!token) {
      handleAuthentication();
      
    } else {
      fetchCredentials();
     
      fetchUserData();
    }
  }, [token]);

  const handleAuthentication = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      fetchToken(code);
    } else {
      window.location.href = cognitoLoginUrl;
    }
  };

  const fetchToken = async (code) => {
    try {
      const bodyParams = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: clientId,
        redirect_uri: redirectUri,
        code,
      });

      const response = await fetch(tokenURI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: bodyParams,
      });

      const data = await response.json();

      if (response.ok) {
        const idToken = data.id_token;
        if (idToken) {
          setToken(idToken);
          localStorage.setItem('token', idToken);

          const decodedToken = jwtDecode(idToken);
          setUserAttributes(decodedToken);
          localStorage.setItem('userAttributes', JSON.stringify(decodedToken));
        }
      } else {
        console.error('Error fetching token:', data.error);
      }
    } catch (error) {
      console.error('Response Issue: Error fetching token:', error);
    }
  };

  const fetchUserData = () => {
   

    if (userAttributes) {
      setUserEmail(userAttributes.email);
      setUserFirstName(userAttributes.given_name);
      setUserLastName(userAttributes.family_name);
    }
  };

  useEffect(() => {
    if (userAttributes) {
 
      fetchUserData();
    }
  }, [userAttributes]);


  

  const [selectedSection, setSelectedSection] = useState(() => {
    return localStorage.getItem('selectedSection') || null;
  });
  
  const handleSectionChange = (sectionId) => {
    setSelectedSection(sectionId);
    localStorage.setItem('selectedSection', sectionId);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setToken(null);
      localStorage.removeItem('token');
      localStorage.removeItem('userAttributes');
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };



  return (
    <AuthProvider>
      <div>
        {token ? (
          <div className="dashboard">
            
            <Sidebar handleSectionChange={handleSectionChange} userAttributes={userAttributes} />
            <div className="content">
              <div className="top-bar">
                <div className="logo">
                  {/* <img src="amazon-logo.png" alt="Amazon Logo"></img> */}
                </div>
                <a href="https://amazon.enterprise.slack.com/archives/C07ARSBDPE3" className="help-link"  target="_blank" > Help </a> 
                <div className="sign-in">
                  <a href="#">{userFirstName} {userLastName}</a>
                </div>
              </div>
              {selectedSection === 'Main' && <Main />}
              {selectedSection === 'EstimateValidation' && <EstimateValidation />}
              {selectedSection === 'ModernizationEstimator' && <ModernizationEstimator />}
              {selectedSection === 'ModernizationAtScale' && <ModernizationAtScale />}
              {selectedSection === 'WorkloadAssessmentPro' && <WorkloadAssessmentPro />}
              {selectedSection === 'AddCust' && <AddCust />}
              {selectedSection === 'ViewCust' && <ViewCust />}
              {selectedSection === 'UpdateCust' && <UpdateCust />}
              {selectedSection === 'AddTemp' && <AddTemp />}
              {selectedSection === 'ViewTemp' && <ViewTemp />}
              {selectedSection === 'UpdateTemp' && <UpdateTemp />}
              {selectedSection === 'DispositionModelling' && <DispositionModelling />}
              {selectedSection === 'StorageRefactoring' && <StorageRefactoring />}
              {selectedSection === 'DRMaster' && <DRMaster />}
              {selectedSection === 'BackupAssist' && <BackupAssist />}
              {selectedSection === 'MapTagger' && <MapTagger />}
              <Footer />
            </div>
          </div>
        ) : (
          <div className="loading-overlay">
            <p>Loading...</p>
          </div>
        )}
      </div>
    </AuthProvider>
  );
};

export default App;